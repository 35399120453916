<template>
  <div>
    <div class="operations">
      <div style="flex:1"></div>
      <a-button @click="showForm = true">添加</a-button>
    </div>
    <GigaTable :gData="dataSource" :gColumns="columns" />
    <LicensedBrandForm :visible="showForm" :formData="formState" :filmCategory="filmCategory" @formCancel="handleCancel" @formOk="handleOk" /> 
  </div>
</template>
<script>
import { getLicensedBrands, editLicenseBrand, getVehicleFilmCategory } from '../http/api'
import GigaTable from '../components/GigaTable.vue'
import LicensedBrandForm from '../components/form/LicensedBrandForm'
export default {
  components: {
    GigaTable,
    LicensedBrandForm
  },
  data() {
    return {
      showForm: false,
      formState: {},
      dataSource: [],
      columns: [{
        title: 'id',
        dataIndex: 'id',
        key: 'id',
        width: 80
      }, {
        title: '名称',
        dataIndex: 'name',
        key: 'name'
      }, {
        title: '价格',
        dataIndex: 'wrapFilmPrice',
        key: 'wrapFilmPrice'
      }, {
        title: '简介',
        dataIndex: 'description',
        key: 'description',
        customRender: (val) => {
          return (
            <a-tooltip title={val.value} >
              <div style={{maxWidth: '100%' }}>
                {val.value.substring(0, 10)}{val.value.length > 10 ? '...' : ''}
              </div>
            </a-tooltip>
          )
        }
      }, {
        title: 'logo',
        dataIndex: 'logo',
        key: 'logo',
        width: 140,
        customRender: val => {
          return <img src={val.value && val.value.url || ''} style="width: 40px" />
        }
      }, {
        title: '膜的类型',
        dataIndex: 'filmCategories',
        key: 'filmCategories',
        customRender: (val) => {
          return <div>{val.value.map(e => (<span v-html={e.name + '，'}></span>)) }</div>
        }
      }, {
        title: '是否发布',
        dataIndex: 'isPublished',
        key: 'isPublished',
        width: 100,
        customRender: val => <span v-html={val.value ? '已发布' : '未发布'} />
      }, {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        customRender: (val) => {
          return (
            <div>
              <a-dropdown trigger={['click']} overlay={(
                <a-menu>
                  <a-menu-item onClick={() => this.editLicenseBrand(val.record)}>
                    <span>编辑</span>
                  </a-menu-item>
                  <a-menu-item onClick={() => this.editLicenseBrand(val.record, 'publish')}>
                    <span v-html={val.record.isPublished ? '取消发布' : '发布'}></span>
                  </a-menu-item>
                  <a-menu-item onClick={() => this.editLicenseBrand(val.record, 'del')}>
                    <span>删除</span>
                  </a-menu-item>
                </a-menu>
              )}>
                <a-button>操作</a-button>
              </a-dropdown>
            </div>
          )
        }
      }]
    }
  },
  created() {
    this.getLicensedBrands()
    this.getVehicleFilmCategory()
  },
  methods: {
    setImg(e, type) {
      let img = e[0]
      let formState = this.formState
      formState[type] = img.url
      this.formState = JSON.parse(JSON.stringify(formState))
    },
    handleOk(formState) {
      editLicenseBrand({variables: formState}).then(res => {
        console.log(res)
        this.handleCancel()
        this.getLicensedBrands()
      }).catch(err => {
        console.log(err)
      })
    },
    editLicenseBrand(record, type) {
      if (type == 'del') {
        this.handleOk({id: record.id, isDeleted: true})
      } else if (type == 'publish') {
        this.handleOk({id: record.id, isPublished: !record.isPublished})
      } else {
        record.filmCategoryIds = record.filmCategories.map(e => e.id)
        record.logoUrl = record.logo.url
        this.formState = record
        this.showForm = true
      }
    },
    handleCancel() {
      this.showForm = false
      this.formState = {}
    },
    getLicensedBrands() {
      getLicensedBrands().then(res => {
        if (res.errors) {
          this.$message.error(res.errors[0].message)
          return
        }
        let dataSource = res.data.licensedBrands
        console.log(dataSource)
        this.dataSource = dataSource
      })
    },
    getVehicleFilmCategory() {
      getVehicleFilmCategory().then(res => {
        if (res.errors) {
          this.$message.error(res.errors[0].message)
          return
        }
        let dataSource = res.data.vehicleFilmCategory
        this.filmCategory = dataSource
      })
    }
  },
}
</script>
<style lang="scss" scoped>
  
</style>