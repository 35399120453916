<template>
  <a-modal :visible="visible" title="添加授权品牌" destroyOnClose :maskClosable="false"  @ok="handleOk()" @cancel="handleCancel">
      <a-form
        ref="form"
        :model="formState"
        name="form"
        :validateOnRuleChange="false"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
        autocomplete="off"
        scrollToFirstError
      >
        <a-form-item
          label="名称"
          name="name"
          :rules="[{ required: true, message: '输入名称' }]"
        >
          <a-input v-model:value="formState.name" name="name" />
        </a-form-item>
        <a-form-item
          label="*logo"
          name="logoId"
        >
          <GigaUpload v-model:value="formState.logoId" name="logoId" :imageUrl="formState.logoUrl" @setImg="setImg($event, 'logo')"  />
        </a-form-item>
        <a-form-item
          label="简介"
          name="description"
        >
          <a-textarea v-model:value="formState.description" name="description" />
        </a-form-item>
        <a-form-item
          label="价格"
          name="wrapFilmPrice"
        >
          <a-input v-model:value="formState.wrapFilmPrice" name="wrapFilmPrice" />
        </a-form-item>
        <a-form-item
          label="膜的类型"
          name="filmCategoryIds"
          :rules="[{ required: true, message: '选择类型' }]"
        >
          <a-select
            ref="select"
            mode="multiple"
            v-model:value="formState.filmCategoryIds"
            style="width: 100%"
          >
            <a-select-option v-for="item in filmCategory" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="排序"
          name="displayOrder"
        >
          <a-input v-model:value="formState.displayOrder" name="displayOrder" />
        </a-form-item>
      </a-form>
    </a-modal>
</template>
<script>
import { ref, watch } from 'vue';
import GigaUpload from '../GigaUpload.vue'
export default {
  name: 'LicensedBrandForm',
  components: {
    GigaUpload
  },
  props: ['visible', 'formData', 'filmCategory'],
  setup(props) {
    const formState = ref(props.formData)
    watch(() => props.formData, (newVal) => formState.value = newVal)
    return {
      formState,
      form: ref(null),
    };
  },
  
  methods: {
    setImg(e, type) {
      let img = e[0]
      let formState = this.formState
      formState[`${type}Id`] = img.id
      formState[`${type}Url`] = img.url
      this.formState = JSON.parse(JSON.stringify(formState))
    },
    handleOk() {
      this.form.validateFields().then(res => {
        res.displayOrder = res.displayOrder ? parseInt(res.displayOrder) : 0
        let formState = Object.assign(this.formState, res)
        if (formState.logoId || formState.logo.id) {
          formState.coverImageId = formState.logoId || formState.logo.id
        }
        this.$emit('formOk', formState)
      }).catch(err => {
        console.error(err)
        this.$message.error(err.errorFields[0].errors[0])
      })
    },
    handleCancel() {
      this.$emit('formCancel')
    },
  },

}
</script>